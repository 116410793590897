import { white } from 'src/styles/colors'
import styled from 'styled-components'

import BackgroundMobile from '../../assets/image/background-hero-fortaleza-sm.png'
import BackgroundMD from '../../assets/image/background-hero-fortaleza-md.png'
import BackgroundLG from '../../assets/image/background-hero-fortaleza-lg.png'
import BackgroundXL from '../../assets/image/background-hero-fortaleza-xl.png'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-image: url(${BackgroundMobile});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
    height: 1330px;
  }

  @media ${device.desktopLG} {
    background-image: url(${BackgroundLG});
    height: 1390px;
  }

  @media ${device.desktopXL} {
    background-image: url(${BackgroundXL});
    height: 1489px;
  }
`

export const Card = styled.div`
  background-color: ${white};
  border-radius: 8px;

  @media ${device.tablet} {
    width: 232px;
    height: 156px;
  }
`

export const AthleticoBenefits = styled.div`
.benefits {
  margin-bottom: 16px;
}
.benefits:last-child {
  margin-bottom: 0;
 }
`

export const Cards = styled.div`
.card {
  margin-right: 0;
  margin-bottom: 16px;

  @media ${device.desktopXL} {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.card:last-child {
  margin-bottom: 0;
  
  @media ${device.desktopXL} {
    grid-column: 2;
  }
 }
`
