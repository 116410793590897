import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      appCartaoHeroFortaleza: imageSharp(fluid: {originalName: { regex: "/app-cartao-hero-fortaleza/" }}) {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      logosSectionFortaleza: imageSharp(fluid: {originalName: { regex: "/logos-section-fortaleza/" }}) {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
