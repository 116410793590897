import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Container = styled.div`
  border-radius: 16px;
  position: relative;
  padding: 32px;

  @media ${device.tablet} {
    width: 512px;
    height: 267px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 14px;
  top: 0;
  z-index: 10;
  outline: none !important;
`
