import styled, { keyframes, css } from 'styled-components'
import { gray, orange, white } from 'src/styles/colors'
import { breakpoints, device } from 'src/styles/breakpoints'

type ContainerProps = {
  isModal?: () => void;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`
export const Container = styled.div<ContainerProps>`
  background-color: ${white};
  overflow-y: auto;

  h3 {
    font-family: 'Sora';
  }

  img {
    max-width: 185px;
  }

  ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 100vh;
      padding: 80px 24px 30px 24px;
      position: fixed;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;

      @media (min-width: ${breakpoints.md}) {
        max-width: 596px;
        padding: 40px 80px;
      }
    `
  )}

  .form--default {
    label {
      color: #6A6C72;
      margin-bottom: 4px;
    }

    .form-label-check {
      &::before {
        top: 34px;

        @media (min-width: ${breakpoints.md}) {
          top: 26px;
        }
      }
    }
    .form-input-check {
      &:checked ~ label {
      &::after {
        top: 37px;
          @media (min-width: ${breakpoints.md}) {
            top: 29px;
          }
        }
      }
    }
  }

  &.sent {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

  &.under-age {
    img {
      max-width: 204px;
    }
  }

  &.filled {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
  outline: none !important;

  &:focus{
    outline: none;
  }
`

export const Checkbox = styled.div`
  margin: 10px 0;

  @media ${device.tablet} {
    text-align: left !important;
  }

  input {
    display: contents;
  }

  .form-label-check {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;

    &::before, &::after {
      display: block;
      position: absolute;
      content: '';
    }

    &::before {
      border: 2px solid ${gray['500']};
      border-radius: 3px;
      height: 20px;
      left: 0;
      top: 2px;
      width: 20px;
    }

    a {
      &:hover {
        color: ${gray['600']};
      }
    }
  }

  .form-input-check {
    height: 0 !important;
    opacity: 0;
    width: auto;

    &:disabled ~ label {
      opacity: 0.6;
    }
    &:checked ~ label {
    &::before {
      background: linear-gradient(7.83deg, ${orange.dark} 0%, ${orange.base} 100%);
      border: 1px solid ${orange.dark};
    }
    &::after {
      left: 7px;
      top: 5px;
      width: 6px;
      height: 10px;
      border: solid ${white};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);

      @media ${device.tablet} {
          top: 5px;
        }
      }
    }
  }
`
