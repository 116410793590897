import React from 'react'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useWidth from 'src/hooks/window/useWidth'
import ImageWebp from 'src/components/ImageWebp'

import FlowerGraphism from '../../assets/image/flower-graphism.png'

import dataJson from './data/data.json'

import * as S from './styles'
interface IContaDigitalGratuitaProps {
  openModal: (sectionOrder: string, dataLayer: IDataLayerParams) => void;
  sectionOrder: string;
}

const ContaDigitalGratuita = ({ openModal, sectionOrder }: IContaDigitalGratuitaProps) => {
  const benefitsDigitalAccount: string[] = dataJson
  const windowWidth = useWidth(360)
  const WIDTH_MD = 768

  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row justify-content-lg-between'>
          <div className='col-12 col-md-6 col-lg-4 col-xl-5 d-md-flex justify-content-md-center flex-md-column'>
            {windowWidth >= WIDTH_MD && (
              <ImageWebp
                arrayNumbers={ [ 0, 339, 339, 457 ]}
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-1/image.webp'
                altDescription='Ilustração de uma mulher aproveitando os beneficios da conta digital gratuita Inter'
              />
            )}
          </div>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6 offset-xl-1 pl-md-0 pr-xl-0 d-md-flex flex-md-column justify-content-md-center'>
            <h2 className='fs-32 lh-40 fs-md-40 lh-md-50 fw-400 text-grayscale--500 mb-4'>
              Conta digital gratuita, cartão de crédito sem anuidade e com muitos pontos!
            </h2>
            <p className='fs-18 lh-22 fw-400 text-grayscale--500 mb-0'>
              Um Super App que conecta soluções que a sua vida precisa em um só lugar. Lá você vai encontrar:
            </p>
            <div className='d-none d-lg-block'>
              <S.DigitalAccountBenefits className='my-4'>
                {benefitsDigitalAccount.map((text: string) => (
                  <div key={text} className='benefits d-flex align-items-center'>
                    <div className='mr-2'>
                      <img src={FlowerGraphism} alt='Icone Flower Graphism' />
                    </div>
                    <div>
                      <h3 className='fs-18 lh-22 fw-700 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                  </div>
              ))}
              </S.DigitalAccountBenefits>
            </div>
            <div className='d-none d-lg-block'>
              <button
                onClick={() => openModal(sectionOrder, {
                  section: 'dobra_03',
                  section_name: 'Conta digital gratuita, cartão de crédito sem anuidade e muito cashback!',
                  element_action: 'click button',
                  element_name: 'Abrir conta',
                })}
                title='Abrir conta'
                className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center mw-100'
              >
                Abrir conta
              </button>
            </div>
          </div>
          <div className='col-12 d-lg-none'>
            <S.DigitalAccountBenefits className='my-4'>
              {benefitsDigitalAccount.map((text: string) => (
                <div key={text} className='benefits d-flex align-items-center'>
                  <div className='mr-2'>
                    <img src={FlowerGraphism} alt='Icone Flower Graphism' />
                  </div>
                  <div>
                    <h3 className='fs-18 lh-22 fw-700 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: text }} />
                  </div>
                </div>
              ))}
            </S.DigitalAccountBenefits>
          </div>
          <div className='col-12 d-lg-none d-flex justify-content-md-center'>
            <button
              onClick={() => openModal(sectionOrder, {
                section: 'dobra_03',
                section_name: 'Conta digital gratuita, cartão de crédito sem anuidade e muito cashback!',
                element_action: 'click button',
                element_name: 'Abrir conta',
              })}
              title='Abrir conta'
              className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center mw-100'
            >
              Abrir conta
            </button>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default ContaDigitalGratuita
