import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import BackgroundMobile from '../../assets/image/background-inidique-amigos-mobile.png'
import BackgroundMD from '../../assets/image/background-inidique-amigos-md.png'
import BackgroundLG from '../../assets/image/background-inidique-amigos-lg.png'
import BackgroundXL from '../../assets/image/background-inidique-amigos-xl.png'

import BackgroundCampoFutebol from '../../assets/image/background-campo-futebol.png'

export const Section = styled.section`
  background-image: url(${BackgroundMobile});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    background-image: url(${BackgroundMD});
  }

  @media ${device.desktopLG} {
    height: 623px;
    background-image: url(${BackgroundLG});
  }

  @media ${device.desktopXL} {
    height: 928px;
    background-image: url(${BackgroundXL});
  }

  button, a {
    height: 48px;
    @media ${device.tablet} {
      width: 178px;
    }
  }
`

export const DivBackgroundCampoFutebol = styled.div`
  @media ${device.desktopLG} {
    width: 935px;
    height: 483px;
    background-image: url(${BackgroundCampoFutebol});
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media ${device.desktopXL} {
    width: 1127px;
    height: 582px;
  }
`
