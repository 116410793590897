import React from 'react'

import IconWhatsapp from './assets/images/icon-whatsapp.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as S from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
interface IWhatsappBottomSheet {
  whatsappMessage: string;
  title: string;
  subTitle: string;
  dataLayer: IDataLayerParams;
}

const WhatsappBottomSheet = ({ whatsappMessage, title, subTitle, dataLayer }: IWhatsappBottomSheet) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <S.Wrapper>
      <div>
        <hr />
      </div>
      <div>
        <p className='fs-16 lh-20 text-grayscale--500 fw-600'>{title}</p>
        <p className='fs-14 lh-16 text-grayscale--400'>{subTitle}</p>
      </div>
      <a
        href={`https://api.whatsapp.com/send?text=${whatsappMessage}`}
        className='btn btn--lg bg-orange--base text-none text-white rounded-2 fs-14 lh-16 fw-700 mt-2 mw-100 w-100'
        onClick={() => sendDatalayerEvent(dataLayer)}
      >
        Compartilhar via WhatsApp
        <div className='ml-2'>
          <img src={IconWhatsapp} alt='Whatsapp Icon' width={24} height={24} />
        </div>
      </a>
    </S.Wrapper>
  )
}

export default WhatsappBottomSheet
