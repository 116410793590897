import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

import { Modal } from 'src/components/Modal'
import PopupWhatsapp from 'src/components/PopupWhatsapp'
import WhatsappBottomSheet from 'src/components/WhatsappBottomSheet'

import * as S from './styles'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface IIndiqueAmigosTorcedoresProps {
  sectionOrder: string;
}

const IndiqueAmigosTorcedores = ({ sectionOrder }: IIndiqueAmigosTorcedoresProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const windowWidth = useWidth(300)
  const domReady = useDomReady()
  const WIDTH_MD = 768

  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ bottomSheet, setBottomSheet ] = useState(false)

  const buttonTitle = 'Convocar a galera'
  const whatsappMessage = 'Aproveite a parceria Inter + Fortaleza e venha simplificar a vida com uma Conta Digital gratuita e cartão com acúmulo de pontos. Baixe o SuperApp: https://intergo.app/3c2c5bf8'

  const dataLayer: IDataLayerParams = {
    section: 'dobra_04',
    section_name: 'Indique seus amigos torcedores e aproveite.',
    element_action: 'click button',
    element_name: 'Compartilhar via whatsapp',
  }

  const handleClick = () => {
    sendDatalayerEvent({
      section: `dobra_${sectionOrder}`,
      section_name: 'Indique amigos torcedores e aproveite ainda mais a parceria Athletico + Inter!',
      element_action: 'click button',
      element_name: buttonTitle,
    })
    if (windowWidth >= WIDTH_MD) {
      setIsPopupOpen(!isPopupOpen)
    } else {
      setBottomSheet(!bottomSheet)
    }
  }

  const PopupExitPage = domReady && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen} locationToRender={document.body}>
      <PopupWhatsapp
        whatsappMessage={whatsappMessage} closeModal={() => setIsPopupOpen(false)}
        title='Chame seus amigos para aproveitar os benefícios da parceria Inter + Fortaleza'
        subTitle='Você pode compartilhar esse link com quantos amigos quiser' dataLayer={dataLayer}
      />
    </Modal>
  )

  const BottomSheetMobile = domReady && (
    <Modal isModalOpen={bottomSheet} setIsModalOpen={setBottomSheet} locationToRender={document.body}>
      <WhatsappBottomSheet
        whatsappMessage={whatsappMessage}
        title='Chame seus amigos para aproveitar os benefícios da parceria Inter + Fortaleza'
        subTitle='Você pode compartilhar esse link com quantos amigos quiser' dataLayer={dataLayer}
      />
    </Modal>
  )

  return (
    <S.Section className='py-5'>
      {PopupExitPage}
      {BottomSheetMobile}
      <div className='container h-100'>
        <div className='row h-100 align-content-lg-center'>
          <div className='col-12 text-center'>
            <S.DivBackgroundCampoFutebol className='d-lg-flex justify-content-lg-center align-items-lg-center mx-md-auto'>
              <div>
                <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fw-400 text-white mb-4'>Indique seus amigos torcedores e aproveite.</h2>
                <p className='fs-18 lh-22 text-white mb-3'>Você já sabe que nossa conta campeã, além de simplificar a vida, <span className='d-lg-block'>também é líder em economia e muitas outras vantagens.</span></p>
                <p className='fs-18 lh-22 text-white'>Que tal deixar esse time ainda mais completo e trazer seus <span className='d-lg-block'>amigos para aproveitarem tudo isso com você?</span></p>
                <div className='py-2'>
                  <a
                    onClick={handleClick}
                    title={buttonTitle}
                    className='btn btn-orange--extra btn--lg rounded-2 text-none text-md-center'
                  >
                    {buttonTitle}
                  </a>
                </div>
              </div>
            </S.DivBackgroundCampoFutebol>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default IndiqueAmigosTorcedores
