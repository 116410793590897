import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  height: 37vh;
  width: 100%;
  background-color: white;
  z-index: 30;
  box-shadow: 0px -3px 21px 3px rgba(0,0,0,0.20);
  border-radius: 8px 8px 0px 0px;
  padding: 0 25px 25px 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  hr {
    position: relative;
    border: none;
    background: ${grayscale[200]};
    width: 48px;
    height: 4px;
    border-radius: 9px;
    height: 4px;
}
`
